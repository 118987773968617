.AppOpenSans {
  font-family: Oxygen;
}
.TopLinkText {
  margin: 0px 10px;
}
.TopLinkText:hover {
  text-decoration: underline;
  cursor: pointer;
}

.ContactButton {
  background-color: black;
  width: 8em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.ContactButton:hover {
  border: 1px solid #8b8600;
  background-color: white;
  margin-right: 0px;
  color: #8b8600;
}

.HeroSectionImage {
  height: auto;
  width: 40vw;
}

.HeroSectionImage:hover {
  width: 41vw;
}
